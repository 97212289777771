// import { useEffect, useState } from "react";
import { PLASMIC } from "constants/Plasmic";
import Routes from "../src/routes/index";
import ModalProvider from "mui-modal-provider";
import useAuth from "hooks/useAuth";
import Loader from "components/Loader";
import { PlasmicRootProvider } from "@plasmicapp/loader-react";
import { useAuth0 } from '@auth0/auth0-react';
import { App as CapApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { useEffect } from "react";
import { GlobalDebug } from "utils/remove-consoles";
import useInactivityLogout from "hooks/useInactivityLogout";
import Chatbtn from "components/third-party/ChatBtn";

const App = () => {
  const { isLoading } = useAuth();
  const { loading: logoutLoading } = useInactivityLogout();
  const { handleRedirectCallback } = useAuth0();
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "PRODUCTION" || process.env.REACT_APP_ENV === "STAGING"   ) {
      GlobalDebug(false);
    }
    CapApp.addListener('appUrlOpen', async ({ url }) => {
      console.log("App Open");
      if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
        await handleRedirectCallback(url);
      }
      // No-op on Android
      await Browser.close();
    });
  }, [handleRedirectCallback]);

  if (isLoading || logoutLoading) return <Loader />;

  return (
    <PlasmicRootProvider loader={PLASMIC} disableLoadingBoundary={false}>
      <ModalProvider>
        <Routes />
        <Chatbtn />
      </ModalProvider>
    </PlasmicRootProvider>
  );
};

declare module "@mui/material/styles" {
  interface Palette {
    mmm?: {
      main: string;
    };
  }

  interface PaletteOptions {
    mmm?: {
      main: string;
    };
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    mmm: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    mmm: true;
  }
}

declare module "@mui/material/InputBase" {
  interface InputBasePropsColorOverrides {
    mmm: true;
  }
}

export default App;
